/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "../CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { SocialIcon } from 'react-social-icons';
import Mood from "@material-ui/icons/Mood";

import headerLinksStyle from "../../assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";


function HeaderLinks({ ...props }) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const { classes } = props;

  const buttonsStyle = {
    margin: "5px"
  }

  return (
    <List className={classes.list + " " + classes.mlAuto + " " + classes.right}>
      <ListItem className={classes.listItem + " " + classes.mlAuto}>
        <Tooltip
          id="tooltip-email"
          title="Escríbeme"
          placement="left"
          classes={{ tooltip: classes.tooltip }}
        >
          <SocialIcon style={buttonsStyle} network="email" url="mailto:meli@melissa-soto.com" fgColor="#d3d3d3" bgColor="white" alt="meli@melissa-soto.com" />
        </Tooltip>
      </ListItem>
      <ListItem style={buttonsStyle} className={classes.listItem + " " + classes.mlAuto}>
        <Tooltip
          id="tooltip-follow"
          title="Sígueme en Instagram"
          placement="left"
          classes={{ tooltip: classes.tooltip }}
        >
          <SocialIcon network="instagram" url="http://instagram.com/melissa.soto.joyeria" fgColor="#d3d3d3" bgColor="white" />
        </Tooltip>
      </ListItem>
      <ListItem style={buttonsStyle} className={classes.listItem + " " + classes.mlAuto}>
        <Tooltip
          id="tooltip-call"
          title="Llámame y Conversemos :)"
          placement="left"
          classes={{ tooltip: classes.tooltip }}
        >
          <SocialIcon network="whatsapp" url="tel:(506)8707-1999" fgColor="#d3d3d3" bgColor="white" />
        </Tooltip>
      </ListItem>
      <Tooltip
        id="tooltip-right"
        title="Pronto!"
        placement="right"
        classes={{ tooltip: classes.tooltip }}
      >
        <ListItem style={buttonsStyle} className={classes.listItem}>

          <Button
            href="#"
            color={window.innerWidth < 960 ? "info" : "white"}
            className={classes.navButton}
            disabled
            round
          >
            <Mood className={classes.icons} /> Crear Pieza
        </Button>

        </ListItem>
      </Tooltip>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};

export default withStyles(headerLinksStyle)(HeaderLinks);
