import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite"
// Styles
import '../../App.css';
import homeStyle from "../../assets/jss/material-kit-pro-react/homeStyle.jsx";
import bg1 from '../../assets/img/bg1.svg'
// Components
import Header from "../../components/Header/Header.jsx";
import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import SectionDescription from "../Sections/SectionDescription.jsx";
import StackGrid, { transitions } from "react-stack-grid";
import TiltShape from '../../TiltShape';
import TiltText from '../../TiltText';
import Carousel from "../HomePage/Carousel";

//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "../../components/CustomButtons/Button.jsx";

//import modalStyle from "../../assets/jss/material-kit-pro-react/modalStyle.jsx";



const options = {
  max: 10,
  perspective: 1000,
  scale: 1.02,
}

const viewWidth = window.innerWidth;

function importAll(r) {
  return r.keys().map(r);
}
const imagesStyle = {
  width : '100%',
  height : '100%',
  cursor : 'pointer'
}

const images = importAll(require.context('../../assets/img/Photos/', true, /\.(png|jpg|svg)$/));

const items = images.map((item, key) =>
  <img key={item} src={item} />
);

// Address Bar Animation
const f = ['❤', '❤', '❤', '❤', '💍', '💍', '💍', '💍', '💛', '💛', '💛', '💛', '✨', '✨', '✨', '✨', '💎', '💎', '💎', '💎', '🌟', '🌟', '🌟', '🌟', '💙', '💙', '💙', '💙']
function loop() {
  window.location.hash = f[Math.floor((Date.now() / 100) % f.length)];
  setTimeout(loop, 50);
}
loop();

const gotoInstagram = () => {
  window.location.href = "https://www.instagram.com/melissa.soto.joyeria/";
}

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class HomePage extends React.Component {  
  constructor(props) {
    super(props);
    this.state = { viewWidth: 0, viewHeight: 0, liveDemo: false };
  }

  updateWindowDimensions() {
    this.setState({ viewWidth: window.innerWidth, viewHeight: window.innerHeight });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    this.updateWindowDimensions(this);
    window.addEventListener('resize', this.updateWindowDimensions(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions(this));
  }
  render() {      
    const { classes } = this.props;
    const { scaleDown } = transitions;
  
    return (
      <div id="App">         
       <div id="Container">
       <Header
          brand="❤💎💍 Joyería con Intención 💍💎❤"
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
          color="transparent"
           changeColorOnScroll={{
            height: '100',
            color: "black"
          }} 
        /> 
       <TiltShape onClick={gotoInstagram}
          options={{}}
          className="test"
          style={{
            background: `url(${bg1}) no-repeat center center`               
            
          }}
        >
           <TiltText options={options} onClick={gotoInstagram}>
          </TiltText> 
        </TiltShape>

        <div>
        <h1 className="Title">Colección Anthophila</h1>
        </div>
        <blockquote><p class="quotation"> 
           Los antófilos (Anthophila, gr. ‘que aman las flores’), conocidos comúnmente como abejas, son un clado de insectos himenópteros, sin ubicación en categoría taxonómica, dentro de la superfamilia Apoidea.</p>           
        </blockquote>
        <div>Fotos por: <a href="https://www.facebook.com/marcela.bertozzi">Marcela Bertozzi</a>
             <p></p>
                            Lilly Arce
        </div> 
        <StackGrid            
          style={imagesStyle}
          columnWidth={viewWidth <= 700 ? '100%' : '33.33%'} 
          monitorImagesLoaded={true}
          appear={scaleDown.appear}
          appeared={scaleDown.appeared}
          enter={scaleDown.enter}
          entered={scaleDown.entered}
          leaved={scaleDown.leaved}
          duration={100}
          className="wide"
        >
          {
            items.map((item, key) =>
              <img key={key} src={item.key} style={imagesStyle} alt="" onClick={() => {this.setState({liveDemo:true})}}/>
          )
          }
        </StackGrid>     
        </div>        
         <Footer
          content={
            <div>
              {/* <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} . Hecho con {" "}
                <Favorite className={classes.icon} /> en Costa Rica

              </div> */}
            </div>
          }
        /> 

       {/*  <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.state.liveDemo}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {this.setState({liveDemo:false})}}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => {this.setState({liveDemo:false})}}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Modal title</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <Carousel pictures={items} classes={classes} ></Carousel>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              onClick={() => {this.setState({liveDemo:false})}}
              color="secondary"
            >
              Close
            </Button>
            <Button color="primary">Save changes</Button>
          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

export default withStyles(homeStyle)(HomePage);