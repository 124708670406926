import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/coßre components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "../../components/Card/Card.jsx";


import carouselStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.jsx";

import image1 from "../../assets/img/bg2.png";
import image2 from "../../assets/img/bg2.png";
import image3 from "../../assets/img/bg2.png";

function SectionCarousel(props) {
    const { classes } = props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    return (
      <div style={carouselStyle} id="carousel">
        <div style={carouselStyle}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8} style={carouselStyle}>
              <Card>
                <Carousel {...settings}>
                  <div>
                    <img
                      src={require(image1)}
                      alt="First slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />Yellowstone
                        National Park, United States
                      </h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image2}
                      alt="Second slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />Somewhere Beyond,
                        United States
                      </h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image3}
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />Yellowstone
                        National Park, United States
                      </h4>
                    </div>
                  </div>
                </Carousel>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
  
  export default withStyles(carouselStyle)(SectionCarousel);