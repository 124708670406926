
import {
    container,
    mrAuto,
    mlAuto,
    description
  } from "../../material-kit-pro-react.jsx";


  const descriptionStyle = {
    container,
    textCenter: {
      textAlign: "center"
    },
    aboutDescription: {
      padding: "70px 0 0 0"
    },
    mrAuto,
    mlAuto,
    description
  };
  
  export default descriptionStyle;
  